<div id="calendario">
    <neo-notificacion #notificacion></neo-notificacion>
    
    <div class="botonera-acciones">
      <h3 class="text-h2">{{titulo}}</h3>

        <ng-container>
          <div>
            <ng-template [ngTemplateOutlet]="customAccionesCabecera || defaultTemplate">
            </ng-template>
            <ng-template #defaultTemplate>
                <neo-button class="mr-20" *ngIf="permitirConfigurarCalendario " [text]="'Editar tarifas'" [color]="'primary'"
                    [stroked]="true" [iconClass]="'icon-20 icon-ibm--operations--record'" [disabled]="false"
                    (accion)="onSeleccionFechas()"></neo-button>
            </ng-template>
            <!-- [ngTemplateOutletContext]="{trackByWeekDayHeaderDate: trackByWeekDayHeaderDate}" -->
            <neo-button
                *ngIf="permitirDescargaPdf"
                [icono]="true"
                [color]="'primary'"
                [clase]="'btn-outline btn-margin-left'"
                [iconClass]="'icon-20 icon-ibm--document--pdf'"
                (click)="clickDescargarPdf.emit(true)"
          ></neo-button>
          </div>
        </ng-container>
    </div>

    <!-- filtrado -->
    <div *ngIf="permitirFiltrado" class="tabla-filtros blq-card" [ngClass]="{'contenedorFiltrosOcultarM': numeroFiltros>1, 'contenedorFiltrosOcultarTV': numeroFiltros>2}">

        <neo-filtros-tabla
          [keyFiltrado]="keyFiltrado"
          [valoresIniciales]="valoresInicialesFiltrado"
          (cambioFormulario)="onEventoCambioFormularioFiltros($event)">
            <!-- FILTROS PERSONALIZADOS ENTIDAD -->
            <div class="campo-busqueda">
            <neo-busqueda #filtrosBusqueda
                placeholder="Busqueda"
                [valorDefault]="true"
                [botonLimpiarBuscador]="true"
                (limpiarBuscador)="onLimpiarBuscador()"
                (changeBuscador)="onEventoCambioBuscador($event)"
                class="mr-4 ml-4"
            ></neo-busqueda>
            </div>
    
            <div class="btns-filtrado" *ngIf="!filtradoSinBotonSubmit">
            <neo-button
                            [disabled]="deshabilitarLimpiarFiltros"
                            [text]="'Limpiar filtros'"
                            [basic]="true"
                            [color]="'primary'"
                            [iconClass]="'icon-20 icon-ibm--clean'"
                            (click)="!deshabilitarLimpiarFiltros ? onLimpiarBuscador(): null;"
            ></neo-button>
            <neo-button
                        [text]="'Aplicar filtros'"
                        [color]="'primary'"
                        [flat]="true"
                        (click)="onSubmitFiltrado()"
            ></neo-button>
            </div>
        </neo-filtros-tabla>
    </div>

    <neo-button  *ngIf="permitirAltaEvento"
                [ngClass]="''"
                [miniFab]="true"
                [color]="'primary'"
                [iconClass]="'icon-20 icon-ibm--add'"
                [clase]="'minifab-flotante'"
                (accion)="onClickAltaEvento($event)"
    ></neo-button>

    <div class="calendario-cont blq-card">
        <neo-calendario-cabecera
            [(vista)]="vista" 
            [(diaVista)]="diaVista"
            [locale]="configuracion.locale"
            [botonHoy]="configuracion.botonHoy"
            [vistaAgenda]="configuracion.vistaAgenda"
            [vistaSemana]="configuracion.vistaSemana"
            [vistaDia]="configuracion.vistaDia"
            (vistaCambia)="onVistaCambia($event)"
            (disVistaCambia)="onDiaVistaCambia($event)"
        ></neo-calendario-cabecera>    
        <div [ngSwitch]="vista">
            <mwl-calendar-month-view
                *ngSwitchCase="'month'"
                [viewDate]="diaVista"
                [events]="eventos"
                [activeDayIsOpen]="diaActivoEstaAbierto"
                [locale]="configuracion.locale"
                [refresh]="refrescar"
                [weekStartsOn]="configuracion.semanaEmpiezaEn"
                [weekendDays]="configuracion.diasFinSemana"
                (eventClicked)="onClickEvento($event.event)"
                (dayClicked)="(esFechaBloqueda($event)) ? $event.stopPropagation() : onClickMomentoMes($event.day, $event);"
                [cellTemplate]="(visibilidadChipsEventos) ? (plantillaDiaEventos) ? plantillaDiaEventos : customCellTemplate  : null"
                (beforeViewRender)="beforeMonthViewRender($event)"
            >
            </mwl-calendar-month-view>
            <mwl-calendar-week-view
                *ngSwitchCase="'week'"
                [viewDate]="diaVista"
                [events]="eventos"
                [locale]="configuracion.locale"
                [refresh]="refrescar"
                [weekStartsOn]="configuracion.semanaEmpiezaEn"
                [weekendDays]="configuracion.diasFinSemana"
                [dayStartHour]="configuracion.horaInicioDia"
                [dayEndHour]="configuracion.horaFinDia"
                [hourDuration]="60"
                [headerTemplate]="customHeaderTemplate"
                [hourSegmentTemplate]="customHourSegmentTemplate"
                [hourSegments]="1"
                [hourSegmentHeight]="60"
                [eventTemplate]="customEventWeekTemplate"
                (eventClicked)="onClickEvento($event.event)"
                (hourSegmentClicked)="(!obtenerConfiguracionDia($event.date)?.bloqueo) ? onClickMomentoSemana($event.date) : $event.stopPropagation();"
                (beforeViewRender)="beforeWeekViewRender($event)"
                
            >

            <!--
                [hourSegmentTemplate]="customHourSegmentTemplate"
                [eventTemplate]="customEventWeekTemplate"
                [headerTemplate]="customHeaderTemplate" -->
            </mwl-calendar-week-view>
            <mwl-calendar-day-view
                *ngSwitchCase="'day'"
                [class.bloqueado]="obtenerConfiguracionDia(diaVista)?.bloqueo"
                [viewDate]="diaVista"
                [events]="eventos"
                [locale]="configuracion.locale"
                [refresh]="refrescar"
                [weekStartsOn]="configuracion.semanaEmpiezaEn"
                [weekendDays]="configuracion.diasFinSemana"
                [dayStartHour]="configuracion.horaInicioDia"
                [dayEndHour]="configuracion.horaFinDia"
                [eventTemplate]="customEventDayTemplate"
                [hourSegmentTemplate]="customHourDaySegmentTemplate"
                [hourSegments]="1"
                [hourSegmentHeight]="60"
                [allDayEventsLabelTemplate]="customAllDayEventsLabelTemplate"
                (eventClicked)="onClickEvento($event.event)"
                (hourSegmentClicked)="(!obtenerConfiguracionDia(diaVista)?.bloqueo) ? onClickMomentoDia($event.date) : $event.stopPropagation();"
                (beforeViewRender)="beforeDayViewRender($event)"
            >
            <!-- 
                [eventTemplate]="customEventWeekTemplate" -->
            </mwl-calendar-day-view>
                <neo-calendario-agenda-vista
                 *ngSwitchCase="'agenda'"
                    [viewDate]="diaVista"
                    [events]="eventos"
                    [locale]="configuracion.locale"
                    [refresh]="refrescar"
                    [configuracionDias]="configuracionDias"
                    [claseDesactivar]="claseDesactivar"
                    (eventClicked)="onClickEvento($event)"
                >
            </neo-calendario-agenda-vista>
        </div>
    </div>

    
</div>
  
 

<ng-template [ngTemplateOutlet]="plantillaDiaEventos"></ng-template>

<!-- plantilla personalizada por defecto mes-->
<ng-template #customCellTemplate let-day="day" let-locale="locale">
    <neo-icono *ngIf="day.locked" [iconoClase]="'icon-10 icon-ibm--blocked'"></neo-icono>
    <div [ngClass]="{'bloqueado': day?.locked}">
        <div class="cal-cell-top">
            <span *ngIf="(day?.cssClass && day?.cssClass.length && day?.cssClass != claseSeleccion) || day?.color"
                class="cal-day-badge" [style.background]="day?.color" [ngClass]="day?.cssClass"
                (click)="onClickCambioTipoDia(day); $event.stopPropagation();">
            </span>
            <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <!-- eventos todo el dia -->
            <!-- <pre>{{day.events | json}}</pre> -->
        <ng-container *ngFor="let evento of day.events | slice: 0:numeroEtiquetas; let i = index;">
            <neo-etiqueta-input class="etiqueta-input mb-5" [ngClass]="day?.cssClass" #campoChip
                [bgColor]="auxiliarService.obtenerRgba(evento.color.primary, 0.2)"
                [texto]="''"
                [contenidoTemplate]="contenidoTemplateEtiquetaInput"
                [eliminable]="false"
                (seleccionar)="onClickEvento(evento)" >
                <ng-template #contenidoTemplateEtiquetaInput>
                    <span class="etiquetaInputHora" [style.font-weight]="'normal'">
                        <ng-container *ngIf="evento.allDay; else textoHora">
                            Todo el día
                        </ng-container>
                        <ng-template #textoHora *ngIf="mostrarHorarioEventoVistaMes">
                            {{evento.start | date:'HH:mm' :es}}
                            <span *ngIf="evento.end">
                                - {{evento.end | date:'HH:mm' :es}}
                            </span>
                        </ng-template>
                    </span>
                    &nbsp;
                    <span class="etiquetaInputTitulo">
                        <ng-container *ngIf="evento?.mostrarIcono">
                            <neo-icono [iconoClase]="(evento?.icono) ? evento.icono : 'icon-10 icon-ibm--blocked'"></neo-icono>
                        </ng-container>
                    
                        {{evento.title}}
                    </span>
                </ng-template>
                </neo-etiqueta-input>
        </ng-container>
        <neo-etiqueta-input
            *ngIf="day.events?.length > numeroEtiquetas && (day.events | slice:numeroEtiquetas) as restoSeleccion"
            class="etiqueta-input mb-5 etiquetaVerMas" [ngClass]="{'etiqueta-abreviada-contador': numeroEtiquetas > 1 }"
            [texto]="(numeroEtiquetas == 1) ? day.events .length + ' Eventos' :
            (textoVerMasChipsEventosResponsivo?'...':(restoSeleccion.length).toString() +' más')"
            [clase]="'chipVerMas'"
            [eliminable]="false"
            (seleccionar)="onClickCeldaMesDesplegarInfo(day, day?.cssClass)"
            ></neo-etiqueta-input>
    </div>
</ng-template>

<!-------------------->
<!-- WEEK templates -->
<!-------------------->
<!-- WEEK: personalizacion cabecera -->
<ng-template #customHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked"
    let-eventDropped="eventDropped" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate" let-dragEnter="dragEnter">
    <div class="cal-day-headers" role="row">
        <ng-container *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate">
            <div class="cal-header" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday"
                [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend" [ngClass]="day.cssClass"
                [class.bloqueado]="day.locked" (mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })"
                mwlDroppable dragOverClass="cal-drag-over"
                (drop)="
                                                                                                                                                                                                  eventDropped.emit({
                                                                                                                                                                                                    event: $event.dropData.event,
                                                                                                                                                                                                    newStart: day.date
                                                                                                                                                                                                  })
                                                " (dragEnter)="dragEnter.emit({ date: day.date })" tabindex="0"
                role="columnheader">
        
                <b>{{ day.date | calendarDate: 'weekViewColumnHeader':locale }}</b><br />
                <span>{{
                    day.date | calendarDate: 'weekViewColumnSubHeader':locale
                    }}</span>
                <div *ngIf="(day?.cssClass && day?.cssClass.length && day?.cssClass != claseSeleccion) || day?.color"
                    class="cal-day-badge" [style.background]="day?.color"
                    (click)="onClickCambioTipoDia(day); $event.stopPropagation();">
                </div>
                <neo-icono *ngIf="day.locked" [iconoClase]="'icon-10 icon-ibm--blocked'"></neo-icono>
            </div>
        </ng-container>
    </div>
</ng-template>


<!-- plantilla personalizada. Nos traemos el codigo de plantilla defecto week -->
<!-- https://github.com/mattlewis92/angular-calendar/blob/master/projects/angular-calendar/src/modules/week/calendar-week-view-hour-segment.component.ts -->
<ng-template #customHourSegmentTemplate 
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
      let-daysInWeek="daysInWeek">
    
<div [class.bloqueado]="obtenerConfiguracionDia(segment.date)?.bloqueo"
      [attr.aria-hidden]="
        {}
          | calendarA11y
            : (daysInWeek === 1
                ? 'hideDayHourSegment'
                : 'hideWeekHourSegment')
      "
      class="cal-hour-segment"
      [style.height.px]="segmentHeight"
      [class.cal-hour-start]="segment.isStart"
      [ngClass]="segment.cssClass"
    >
    
      <div class="cal-time" *ngIf="isTimeLabel">
        {{
          segment.displayDate
            | calendarDate
              : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
              : locale
        }}
</div>
<div *ngIf="!isTimeLabel">
    
    <ng-container *ngIf=" obtenerEventos(segment) as eventosSegmento">
        <ng-container *ngFor="let evento of eventosSegmento | slice: 0:numeroEtiquetasWeek; let i = index;">
            <neo-etiqueta-input class="etiqueta-input mb-5" [ngClass]="{'cal-past': evento.meta.isPast}" #campoChip
                [bgColor]="auxiliarService.obtenerRgba(evento.color.primary, 0.2)" [texto]="''"
                [contenidoTemplate]="contenidoTemplateEtiquetaInput" [eliminable]="false"
                (seleccionar)="onClickEvento(evento)">
                <ng-template #contenidoTemplateEtiquetaInput>
                    <span class="etiquetaInputHora" [style.font-weight]="'normal'">
                        <ng-container *ngIf="evento.allDay; else textoHora">
                            Todo el día
                        </ng-container>
                        <ng-template #textoHora>
                            {{evento.start | date:'HH:mm' :es}}
                            <span *ngIf="evento.end">
                                - {{evento.end | date:'HH:mm' :es}}
                            </span>
                        </ng-template>
                    </span>
                    &nbsp;
                    <span class="etiquetaInputTitulo">{{evento.title}}</span>
                </ng-template>
            </neo-etiqueta-input>
        </ng-container>
        <neo-etiqueta-input
            *ngIf="eventosSegmento?.length > numeroEtiquetasWeek && (eventosSegmento | slice:numeroEtiquetasWeek) as restoSeleccion"
            class="etiqueta-input mb-5 etiquetaVerMas"
            [ngClass]="{'etiqueta-abreviada-contador': numeroEtiquetasWeek > 1 }"
            [texto]="(numeroEtiquetasWeek == 1) ? eventosSegmento .length + ' Eventos' :
            (textoVerMasChipsEventosResponsivo?'...': (restoSeleccion.length).toString() +' más')"
            [clase]="'chipVerMas'" [eliminable]="false"
            (seleccionar)="onClickCeldaSemanaDesplegarInfo(segment, eventosSegmento)">
        </neo-etiqueta-input>
    </ng-container>

</div>
    </div>
     <!-- contenido segmento hora week -->
</ng-template>
<!-- plantilla mwl-calendar-day-view mwl-calendar-week-view-->
<!-- https://github.com/mattlewis92/angular-calendar/blob/master/projects/angular-calendar/src/modules/week/calendar-week-view.component.ts -->
<ng-template
      #customEventWeekTemplate
      let-weekEvent="weekEvent"
      let-tooltipPlacement="tooltipPlacement"
      let-eventClicked="eventClicked"
      let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDisabled="tooltipDisabled"
      let-tooltipDelay="tooltipDelay"
      let-column="column"
      let-daysInWeek="daysInWeek"
    >
    <ng-container *ngIf="weekEvent.tempEvent || weekEvent.event as evento">
        <neo-etiqueta-input class="etiqueta-input mb-5" #campoChip *ngIf="evento.allDay"
            [bgColor]="auxiliarService.obtenerRgba(evento.color.primary, 1)" [texto]="''"
            [contenidoTemplate]="contenidoTemplateEtiquetaInput" [eliminable]="false" (seleccionar)="onClickEvento(evento)">
            <ng-template #contenidoTemplateEtiquetaInput>
                <span class="etiquetaInputHora" [style.font-weight]="'normal'">
                    <ng-container *ngIf="evento.allDay; else textoHora">
                        Todo el día
                    </ng-container>
                    <ng-template #textoHora>
                        {{evento.start | date:'HH:mm' :es}}
                        <span *ngIf="evento.end">
                            - {{evento.end | date:'HH:mm' :es}}
                        </span>
                    </ng-template>
                </span>
                &nbsp;
                <span class="etiquetaInputTitulo">{{evento.title}}</span>
            </ng-template>
        </neo-etiqueta-input>
    </ng-container>
</ng-template>

<!-------------------->
<!-- DAY templates -->
<!-------------------->
<!-- DAY: personalizacion seccion todos los eventos -->
<ng-template #customAllDayEventsLabelTemplate>
    <neo-icono *ngIf="configuracionDiaVista?.locked" [iconoClase]="'icon-10 icon-ibm--blocked'"></neo-icono>
    <div class="cal-time-label-column day-badge">
        <span *ngIf="configuracionDiaVista?.color" class="cal-day-badge"
            [style.background]="configuracionDiaVista.color"
            (click)="onClickCambioTipoDia(configuracionDiaVista); $event.stopPropagation();">
        </span>
    </div>
</ng-template>
<ng-template #customHourDaySegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
    let-isTimeLabel="isTimeLabel" let-daysInWeek="daysInWeek">
    <div [class.bloqueado]="obtenerConfiguracionDia(segment.date)?.bloqueo" [attr.aria-hidden]=" 
        {}
          | calendarA11y
            : (daysInWeek === 1
                ? 'hideDayHourSegment'
                : 'hideWeekHourSegment')
      " class="cal-hour-segment" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart"
        [ngClass]="segment.cssClass">

        <div class="cal-time" *ngIf="isTimeLabel">
            {{
            segment.displayDate
            | calendarDate
            : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
            : locale
            }}
        </div>
    </div>
    <div class="cal-celda-dia">
    
        <ng-container *ngIf=" obtenerEventos(segment) as eventosSegmento">
            <ng-container *ngFor="let evento of eventosSegmento | slice: 0:numeroEtiquetasWeek; let i = index;">
                <neo-etiqueta-input class="etiqueta-input mb-5" [ngClass]="{'cal-past': evento.meta.isPast}" #campoChip
                    [bgColor]="auxiliarService.obtenerRgba(evento.color.primary, 0.2)" [texto]="''"
                    [contenidoTemplate]="contenidoTemplateEtiquetaInput" [eliminable]="false"
                    (seleccionar)="onClickEvento(evento)">
                    <ng-template #contenidoTemplateEtiquetaInput>
                        <span class="etiquetaInputHora" [style.font-weight]="'normal'">
                            <ng-container *ngIf="evento.allDay; else textoHora">
                                Todo el día
                            </ng-container>
                            <ng-template #textoHora>
                                {{evento.start | date:'HH:mm' :es}}
                                <span *ngIf="evento.end">
                                    - {{evento.end | date:'HH:mm' :es}}
                                </span>
                            </ng-template>
                        </span>
                        &nbsp;
                        <span class="etiquetaInputTitulo">{{evento.title}}</span>
                    </ng-template>
                </neo-etiqueta-input>
            </ng-container>
            <neo-etiqueta-input
                *ngIf="eventosSegmento?.length > numeroEtiquetasWeek && (eventosSegmento | slice:numeroEtiquetasWeek) as restoSeleccion"
                class="etiqueta-input mb-5 etiquetaVerMas"
                [ngClass]="{'etiqueta-abreviada-contador': numeroEtiquetasWeek > 1 }"
                [texto]="(numeroEtiquetasWeek == 1) ? eventosSegmento .length + ' Eventos' :
                (textoVerMasChipsEventosResponsivo?'...':(restoSeleccion.length).toString() +' más')"
                [clase]="'chipVerMas'" [eliminable]="false"
                (seleccionar)="onClickCeldaSemanaDesplegarInfo(segment, eventosSegmento)">
            </neo-etiqueta-input>
        </ng-container>
    </div>
    <!-- contenido segmento hora week -->
</ng-template>
<ng-template #customEventDayTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
    let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDisabled="tooltipDisabled" let-tooltipDelay="tooltipDelay" let-column="column"
    let-daysInWeek="daysInWeek">
    <ng-container *ngIf="weekEvent.tempEvent || weekEvent.event as evento">
        <neo-etiqueta-input class="etiqueta-input mb-5" #campoChip *ngIf="evento.allDay"
            [bgColor]="auxiliarService.obtenerRgba(evento.color.primary, 1)" [texto]="''"
            [contenidoTemplate]="contenidoTemplateEtiquetaInput" [eliminable]="false"
            (seleccionar)="onClickEvento(evento)">
            <ng-template #contenidoTemplateEtiquetaInput>
                <span class="etiquetaInputHora" [style.font-weight]="'normal'">
                    <ng-container *ngIf="evento.allDay; else textoHora">
                        Todo el día
                    </ng-container>
                    <ng-template #textoHora>
                        {{evento.start | date:'HH:mm' :es}}
                        <span *ngIf="evento.end">
                            - {{evento.end | date:'HH:mm' :es}}
                        </span>
                    </ng-template>
                </span>
                &nbsp;
                <span class="etiquetaInputTitulo">{{evento.title}}</span>
            </ng-template>
        </neo-etiqueta-input>
    </ng-container>
</ng-template>


<!-- configuracion calendario -->
<!-- seleccion rangos -->
<ng-template #dialogoSeleccionFechas>
    <neo-acciones-dialog #accionDialogoSeleccionFechas
    [dialogTittle]="'Aplicar tarifa'"
    [botonera]="[
        {
            label: 'Cancelar',
            id: 'btn-cancelar',
            tipoAccion: 'cerrar',
            type: 'button',
            color: '',
            disabled: grabando,
            activo: true,
            flat: true
        },
        {
            label: 'Guardar',
            id: 'btn-confirmar',
            tipoAccion: 'accion',
            type: 'button',
            color: 'primary',
            disabled: (grabando || rangosTipoSeleccionados.length <= 0),
            activo: true,
            flat: true
        }
    ]"
    (accionBoton)="submitAccionSeleccionFechas($event)"
    (cerrarDialogo)="vaciarSeleccion();refrescarVista()"
    >
    <ng-container *ngIf="true">
        <div class="row"  *ngFor="let rangoSeleccion of rangosTipoSeleccionados; let i=index;">
            <div class="popup-form-campo">
                <neo-formulario [id]="'formularioSeleccionFechas_' + i + '_'"
                    [aplicarFlex]="aplicarFlex"
                    [identificador]="'formularioSeleccionFechas_' + i + '_'"
                    [formularioAncho]="clasesFormularioAnchoEnum"
                    [camposFormulario]="inputsFormularioSeleccionRango"
                    [valoresIniciales]="rangoSeleccion"
                    (changeValueForm)="onCambioFormularioRangoTipo($event, formularioSeleccionFechas_i_, i)"
                ></neo-formulario>
                
            </div>
            <div class="popup-form-btn">
                
            </div>
        </div>
        <div class="row">
            <div class="popup-form-campo">
                <neo-formulario #formularioSemillaSeleccionFechas
                [identificador]="'formularioSemillaSeleccionFechas'"
                [aplicarFlex]="aplicarFlex"
                [formularioAncho]="clasesFormularioAnchoEnum"
                [camposFormulario]="inputsFormularioSeleccionRango"
                [valoresIniciales]="[]"
              ></neo-formulario>
            </div>
            <div class="popup-form-btn">
                <neo-button 
                            [ngClass]="''"
                            [flat]="true"
                            [color]="'primary'"
                            [iconClass]="'icon-20 icon-ibm--add'"
                            [clase]="'btn-cuadrado mt-campo'"
                            (accion)="onClickAnhadirRango()"
                ></neo-button>
            </div>
<!-- <div class="popup-form-campo">
    <neo-formulario #formularioSemillaSeleccionFechas [identificador]="'formularioSemillaSeleccionFechas'"
        [aplicarFlex]="aplicarFlex" [formularioAncho]="clasesFormularioAnchoEnum"
        [camposFormulario]="inputsFormularioSeleccionRango" [valoresIniciales]="[]"></neo-formulario>
</div>
<div class="popup-form-btn">
    <neo-button [ngClass]="''" [flat]="true" [color]="'primary'" [iconClass]="'icon-20 icon-ibm--add'"
        [clase]="'btn-cuadrado mt-campo'" (accion)="onClickAnhadirRango()"></neo-button>
</div> -->
        </div>
        
      
    </ng-container>
    </neo-acciones-dialog>
</ng-template>

<!-- dialogo crear/Modificar tipo dia -->
<ng-template #dialogoFormularioTipoDia>
    <neo-acciones-dialog  #accionDialogoFormularioTipoDia
        [dialogTittle]="'Modificar tarifa'"
        [botonera]="[
        {
            label: 'Cancelar',
            id: 'btn-cancelar',
            tipoAccion: 'cerrar',
            type: 'button',
            color: '',
            disabled: grabando,
            activo: true,
            flat: true
        },
        {
            label: 'Guardar',
            id: 'btn-confirmar',
            tipoAccion: 'accion',
            type: 'button',
            color: 'primary',
            disabled: grabando,
            activo: true,
            flat: true
        }
    ]"
        (accionBoton)="onSubmitAccionDialogoFormularioTipoDia($event)"
        (cerrarDialogo)="onCerrarDialogoFormularioTipoDia($event)"
        >
        Tipos tarifas
    </neo-acciones-dialog>
</ng-template>

<!-- detalle, alta, edicion eventos -->
<!-- dialogo ver mas eventos -->
<ng-template #dialogoVerMasEventos>
    <neo-acciones-dialog  #accionDialogoVerMasEventos
        [dialogTittle]="'Eventos ' + (diaVista | date:'dd MMMM yyyy' : es)"
        [botonera]="[]"
        (cerrarDialogo)="onCerrarDialogoVerMasEventos($event)"
        >
        <!--color evento [style.background]="auxiliarService.obtenerRgba(evento.color.primary, 1)" -->
        <!--color paquete [style.background]="auxiliarService.obtenerRgba(evento.color.secondary, 1)" -->
        <!--color dia tarifa [style.background]="auxiliarService.obtenerRgba(evento.color.dia, 1)" -->
        <ng-container *ngIf="filtrarEventosTodoElDia(eventosDiaActivo) as eventosTodoElDia">
        
            <div class="eventosTodoDia" *ngIf="eventosTodoElDia.length>0">
                <p *ngFor="let evento of eventosTodoElDia" [ngClass]="evento.cssClass">
                    <a (click)="onClickEvento(evento)">
                        Todo el día<strong> {{evento.title}}</strong>
                    </a>
                </p>
            </div>
            </ng-container>
        
        <div class="eventosRangoHorario">
            <p *ngFor="let evento of filtrarEventosRangoHorario(eventosDiaActivo); let i=index;" [ngClass]="evento.cssClass">
                <a (click)="onClickEvento(evento)"
                    [ngStyle]="{background: hover==i  ? auxiliarService.obtenerRgba(evento.color.secondary, 0.2) : '' }"
                    (mouseover)="hover=i" (mouseleave)="hover=-1">
                    <neo-icono [ngClass]="evento.cssClass" [color]="auxiliarService.obtenerRgba(evento.color.primary, 1)"
                        [iconoClase]="'icon-8 icon-ibm--circle--solid mr-5'"></neo-icono>
                    {{evento.start | date:'HH:mm' : es}}<span> - {{evento.end | date:'HH:mm' : es}} </span>
                    <strong>{{evento.title}}</strong>
                </a>
            </p>
        </div>
    </neo-acciones-dialog>
</ng-template>

<!-- dialogo crear/Editar eventos -->
<ng-template #dialogoFormulario>
    <neo-acciones-dialog  #accionDialogoFormulario
        [dialogTittle]="'Evento'"
        [configuracionBotoneraIzquierda]="{clase:  'botoneraIzquierda'}"
        [botoneraIzquierda]="[
        {
            label: 'Eliminar',
            id: 'btn-eliminar',
            tipoAccion: 'accion',
            type: 'button',
            color: 'warn',
            disabled: grabando,
            activo: true,
            basic: true,
            iconoClase:'icon-20 icon-ibm--trash-can icon-24 mr-10'
        }]"    
        [configuracionBotonera]="{ clase:  'botoneraDerecha'}"
        [botonera]="[
        {
            label: 'Cancelar',
            id: 'btn-cancelar',
            tipoAccion: 'cerrar',
            type: 'button',
            color: '',
            disabled: grabando,
            activo: true,
            flat: true
        },
        
        {
            label: 'Guardar',
            id: 'btn-confirmar',
            tipoAccion: 'accion',
            type: 'button',
            color: 'primary',
            disabled: grabando,
            activo: true,
            flat: true
        }
    ]"

    [claseDialogActions]="'acciones-button-block-xs'"
        (accionBoton)="onSubmitAccionDialogoFormulario($event)"
        (cerrarDialogo)="onCerrarDialogoFormulario($event)"
        >
        <neo-formulario #formularioFormulario
          [aplicarFlex]="aplicarFlex"
          [formularioAncho]="clasesFormularioAnchoEnum"
          [camposFormulario]="inputsFormulario"
          [valoresIniciales]="valoresFormulario"
          (changeValueForm)="onCambioFormularioEvento($event)"
          
        ></neo-formulario>
    </neo-acciones-dialog>
</ng-template>




  <!--Diálogo Eliminar archivos-->
<ng-template #dialogConfirmarEliminar>
    <neo-acciones-dialog #accionDialogoConfirmarEliminar
        [dialogTittle]="'Eliminar'"
        [botonera]="[
                    {
                    label: 'No, mantener',
                    id: 'btn-cancelar',
                    tipoAccion: 'cerrar',
                    type: 'button',
                    color: '',
                    disabled: false,
                    activo: true,
                    flat: true
                    },
                    {
                    label: 'Sí, eliminar',
                    id: 'btn-confirmar',
                    tipoAccion: 'accion',
                    type: 'button',
                    color: 'primary',
                    disabled: false,
                    activo: true,
                    flat: true
                    }
                ]"
        (accionBoton)="onSubmitAccionDialogConfirmarEliminar($event)">

        <p>¿Estás seguro de eliminar el siguiente evento?
        </p>        
    </neo-acciones-dialog>
</ng-template>
<!--Diálogo Eliminar archivos-->
