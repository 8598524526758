import { WeekViewAllDayEventRow } from 'calendar-utils';
import { AfterViewChecked, AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, SimpleChange, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FiltrosService } from '@appNeo/neoCore/services/filtros/filtros.service';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';

import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';

import { ClasesFormularioAnchoEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { INPUTSFILTRADO, VALIDATORSFILTROS } from '@appNeo/neoShared/pages/demo-calendario/demo-calendario-data';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { BotonDesplegableService, IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { CalendarioCustomizarFechasService } from '@appNeo/neoShared/services/calendario/calendario-customizar-fechas.service';
import { FormularioFiltrosTablaService } from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { environment } from '@environments/environment';
import { CalendarDateFormatter, CalendarDayViewBeforeRenderEvent, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarView, CalendarWeekViewBeforeRenderEvent, DAYS_OF_WEEK } from 'angular-calendar';
import {
  compareAsc, eachDayOfInterval, isSameDay, isSameMonth,
  subMonths,
  addMonths,
  addDays,
  addWeeks,
  subDays,
  subWeeks,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
  parseISO
} from 'date-fns';
import { addMinutes } from 'date-fns/esm';
import { Subject, Subscription } from 'rxjs';
import { AccionesDialogComponent } from '../../acciones-dialog/acciones-dialog.component';
import { BusquedaComponent } from '../../busqueda/busqueda.component';
import { FormularioComponent } from '../../formulario/formulario.component';
import { NotificacionComponent } from '../../notificacion/notificacion.component';
import { FiltrosTablaComponent } from '../../tabla/filtros-tabla/filtros-tabla.component';
import { INPUTS_SELECCIONRANGO, INPUTS_FORMULARIO_EVENTO, BOTONERAACCIONESCABECERA, CONFIGURACIONDEFECTO } from '../calendario-configuracion';
import { D } from '@angular/cdk/keycodes';

export enum  VistasCalendarioExtendidoEnum {
  Agenda = "agenda"
}
export type VistasCalendario = CalendarView | VistasCalendarioExtendidoEnum;
export interface IConfiguracionCalendario {
  horaInicioDia?: number;
  horaFinDia?: number;
  // la semana empieza el dia
  semanaEmpiezaEn?: DAYS_OF_WEEK,
  // establecer cuales son los fines de semana
  diasFinSemana?: number[],
  // internalizacion
  locale?:string,
  // minutos que se le añade a la horaFin al change horaInicio
  intervaloMinutosCampoHora?: number;
  vistaSemana?:boolean,
  vistaDia?:boolean,
  vistaAgenda ?:boolean
  botonHoy?: boolean
}

export interface IConfiguracionMomento {
  dia: number //Date.getTime(),
  clase?: string; //Se puede aplicar una clase segun el dia
  color?: string,//Se puede aplicar un color al dia, raya esquina izq
  bloqueo?: boolean,
  configuracion?: any; //permite objeto meta, ej: ubicacion para entidades: tarifa, turno, paquete
}

// todo sustituir por IRangoTipoSeleccion[]
export interface IAccionElementosSeleccionados {
  seleccion: Date[];
  accion: string;
}

export interface IRangoTipoSeleccion{
  rango?: IRango,
  idTipo?: any,
  turnos?: any
}

export interface IRango {
  start: string,
  end: string,
}

@Component({
  selector: 'neo-calendario',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None, //necesario para aplicar clases antes de renderizar
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarioCustomizarFechasService,
    },
    FormularioService,
    BotonDesplegableService,
    FiltrosService,
    FormularioFiltrosTablaService
  ],
})
export class CalendarioComponent implements OnInit, AfterViewInit {

// desde fuera machacamos eventos
  @Input() titulo: string = ''; 
  @Input() abrirDialogoClickDia: boolean = true; 
  @Input() eventos;
  @Input() diaVista: Date = new Date();
  configuracionDiaVista;
  @Input() vista: VistasCalendario = CalendarView.Week;
  @Input() configuracion: IConfiguracionCalendario = {};
  /** sobreescritura configuracion interna configuracion calendario**/
  @Input() permitirConfigurarCalendario: boolean = true;
  @Input() permitirDescargaPdf: boolean = true;
  // flag funcionalidad seleccionar celdas
  @Input() permitirModoSeleccion: boolean = false;
  @Input() accionesMultiples: IAccionesBotonDesplegable[] = [];
  @Input() configuracionDias: IConfiguracionMomento[] = [];

  @Input() permitirFiltrado: boolean = false;
  @Input() permitirAltaEvento: boolean = true;
  filtradoSinBotonSubmit: boolean = false;
  @Input() keyFiltrado: string = 'calendario';
  @Input() valoresInicialesFiltrado: object;
  @Input() cerrarDialogoClickEvento: boolean = true;
  @Input() mostrarHorarioEventoVistaMes: boolean = true;

  // @Input() inputsFiltrado: IFormInput[] = [{
  // TODO Hacerlo INPUT EN CUANTO APLICADO COMMIT EN MASTER EVITA DEFINIR VALIDADORES PARA CONTRUIR FORMULARIO TABLA
  @Input() inputsFiltrado: IFormInput[] = INPUTSFILTRADO;
  // TODO BORRAR EN CUANTO APLICADO COMMIT EN MASTER EVITA DEFINIR VALIDADORES PARA CONTRUIR FORMULARIO TABLA
  validatorsFiltros = VALIDATORSFILTROS;

  numeroFiltros: number;
  deshabilitarLimpiarFiltros: boolean = false;
  filtradoInicial = true;


  @Input() plantillaDiaEventos: TemplateRef<any>;
  // si activo, no se se emite evento
  @Input() clickCeldaMesDesplegarInfo: boolean = false;
 
  
  @Input() botoneraAccionesCabecera: any [] = BOTONERAACCIONESCABECERA;

  @Input() formularioGestionEventoDefecto: boolean = true;
  @Input() formularioGestionTipoDiaDefecto: boolean = true;
  @Input() inputsFormulario: IFormInput[] = INPUTS_FORMULARIO_EVENTO;

   // desactivar fechas
   @Input() minFecha: Date = null;
   @Input() maxFecha: Date = null;
   @Input() deshabilitarDiasSinClaseEspecificada: boolean = false;
  @Input() mostrarDiaSeleccionado: boolean = false;
  @Input() mostrarDiaSeleccionadoConEventos: boolean = false;
  // Eventos salida: Date, CalendarView
  // en caso de formulario por defecto para accion crear, gestion interna, resto accion emit
  @Output() clickAccionCabeceraClick = new EventEmitter<any>();
  @Output() clickCambioTipoDia = new EventEmitter<any>();

  // TODO BORRAR
  @Output() clickAccionElementosSeleccionados = new EventEmitter<IAccionElementosSeleccionados>();
  @Output() clickAplicarRangoTipo = new EventEmitter<IRangoTipoSeleccion[]>();
  @Output() clickFiltrar = new EventEmitter<any>();
  @Output() clickMomento = new EventEmitter<Object>();
  @Output() clickEvento = new EventEmitter<Object>();
  @Output() clickElementosSeleccionados = new EventEmitter<Date[]>();
  // en caso de formulario por defecto gestion interna
  @Output() clickGestionarEvento = new EventEmitter<any>();
  @Output() clickEliminarEvento = new EventEmitter<any>();
  @Output() clickAltaEvento = new EventEmitter<any>();
  @Output() clickDescargarPdf = new EventEmitter<any>();

  // CUSTOMIZADO CABECERA BOTONERA GESTION CALENDARIO
  @Input() customAccionesCabecera: TemplateRef<any>;

  // valores por defecto configuracion calendario
  configuracionDefecto: IConfiguracionCalendario  = CONFIGURACIONDEFECTO;

   //despliegue eventos del día actgivo en subfila en vista mes por debajo de la semana actual
  diaActivoEstaAbierto: boolean = false;
  refrescar = new Subject<void>(); 
  visibilidadChipsEventos = true;
  textoVerMasChipsEventosResponsivo = false;
  claseSeleccion = 'cal-day-selected';
  claseDesactivar = 'cal-disabled';
  eventosDiaActivo: any[];

  // seleccion fechas
  modoSeleccion: boolean = false;
  rangosTipoSeleccionados : IRangoTipoSeleccion[] = [
    // {
    //   rango: {
    //     start: "2022-05-01T22:00:00.000Z",
    //     end: "2022-05-01T22:00:00.000Z"
    //   },
    //   idTipo: "9"
    // }
  ];
  diasSeleccionados : Date [] = [];
  seleccionDiaVistaMes: CalendarMonthViewDay;
  grabando = false;
  @ViewChild('dialogoSeleccionFechas') dialogoSeleccionFechas: TemplateRef<any>;
  @ViewChild('accionDialogoSeleccionFechas') accionDialogoSeleccionFechas: AccionesDialogComponent;
  @ViewChild('formularioSemillaSeleccionFechas') formularioSemillaSeleccionFechas: FormularioComponent;
  @ViewChildren(FormularioComponent) formulariosSeleccionFechas: QueryList<FormularioComponent>;
  @ViewChild('notificacion') notificacion: NotificacionComponent;
  // congiguracion layout formularios
  clasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  aplicarFlex: boolean = true;
  inputsFormularioSeleccionRango = INPUTS_SELECCIONRANGO;


  diasMesRenderizados: CalendarMonthViewDay[] ;
  subAccionesMultiples: Subscription;


  // filtrado
  @ViewChild('filtrosBusqueda') filtrosBusqueda: BusquedaComponent;
  @ViewChildren(FiltrosTablaComponent) filtrosTabla: QueryList<FiltrosTablaComponent>;
  valoresFiltrado: object;
  cambioValoresFiltrado = false;
  cambioValorBuscador = false;
  

  // formularioClickEvento y confirmacion eliminar
  @ViewChild('dialogoFormulario') dialogoFormulario: TemplateRef<any>;
  @ViewChild('accionDialogoFormulario') accionDialogoFormulario: AccionesDialogComponent;
  @ViewChild('formularioFormulario') formularioFormulario: FormularioComponent;
  @ViewChild('dialogConfirmarEliminar') dialogConfirmarEliminar: TemplateRef<any>;
  @ViewChild('accionDialogoConfirmarEliminar') accionDialogoConfirmarEliminar: AccionesDialogComponent;

  // dialogoVerMasEventos
  refDialogoVerMasEventos;
  @ViewChild('dialogoVerMasEventos') dialogoVerMasEventos: TemplateRef<any>;
  @ViewChild('accionDialogoVerMasEventos') accionDialogoVerMasEventos: AccionesDialogComponent;

  // dialogoFormularioTipoDia
  @ViewChild('dialogoFormularioTipoDia') dialogoFormularioTipoDia: TemplateRef<any>;
  @ViewChild('accionDialogoFormularioTipoDia') accionDialogoFormularioTipoDia: AccionesDialogComponent;

 

  // @Input() minFecha: Date = subMonths(new Date(), 1);

  // @Input() maxFecha: Date = addMonths(new Date(), 1);

  prevBtnDisabled: boolean = false;

  nextBtnDisabled: boolean = false;

  // this.cdr.markForCheck()
  constructor(
    public auxiliarService: AuxiliarService,
    private dialogo: MatDialog,
    private formularioService: FormularioService,
    private botonDesplegableService: BotonDesplegableService,
    private filtrosService: FiltrosService,
    private filtrosTablaService: FormularioFiltrosTablaService,
  ) {}

  ngOnInit(): void {
    this.configuracion = {...this.configuracionDefecto, ...this.configuracion};

   this.comprobarParametrosConfiguracion();

    this.chequearEstadoPorTamanho();
    this.botonDesplegableService.acciones = this.accionesMultiples;
    this.subcripcionAccionesMultiples();
    if (this.permitirFiltrado) this.establecerFiltros();
    this.numeroFiltros = (this.permitirFiltrado) ? this.numeroFiltros + 1 : 0;
    this.valoresFiltrado = Object.assign({}, this.obtenerValoresFiltrado());

    // minFecha y MaxFecha seleccion rango
    let minFecha = (this.minFecha) ? this.minFecha: undefined;
    let maxFecha = (this.maxFecha) ? this.maxFecha: undefined;
    this.auxiliarService.busquedaPropiedad(this.inputsFormularioSeleccionRango, 'formControlName', 'rango').min = minFecha;
    this.auxiliarService.busquedaPropiedad(this.inputsFormularioSeleccionRango, 'formControlName', 'rango').max = maxFecha;    

    this.auxiliarService.busquedaPropiedad(this.inputsFormularioSeleccionRango, 'formControlName', 'rango').datos = this.configuracionDias;
  }

  ngAfterViewInit(): void {
    this.filtrar();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.configuracionDias;
    if (currentItem?.currentValue) {
      this.auxiliarService.busquedaPropiedad(this.inputsFormularioSeleccionRango, 'formControlName', 'rango').datos = this.configuracionDias;
    }
  }

  subcripcionAccionesMultiples() {
    if (this.subAccionesMultiples) {
      this.subAccionesMultiples.unsubscribe();
    }
    this.subAccionesMultiples = this.botonDesplegableService.accionSeleccionada$.subscribe(accion => {
      // console.log('[CALENDARIO EMIT] clickAccionElementosSeleccionados', {accion, seleccion: this.obtenerDiasSeleccionados()});
      this.clickAccionElementosSeleccionados.emit({accion, seleccion: this.obtenerDiasSeleccionados()});
    });
  }
  

  // **************************************************** //
  // **************** ACCIONES CABECERA****************** //
  // **************************************************** //
  onVistaCambia(vista) {
    this.vista = vista;
    this.cerrarDiaActivoAbierto();
    this.onClickAccionCabecera({ vista: this.vista, diaVista: this.diaVista });
    // console.log('onClick onVistaCambia', vista);
  }

  onDiaVistaCambia(fecha) {
    this.diaVista = fecha;
    this.onClickAccionCabecera({ vista: this.vista, diaVista: fecha });
    // console.log('onClick onDiaVistaCambia', fecha);
  }

  public refrescarVista(): void {
    setTimeout(()=>{
      this.refrescar.next();
    })
  }

  onIntercambioModoSeleccionFechas() {
    // this.modoSeleccion = true;
  }

  onSeleccionFechas(){
    // this.onIntercambioModoSeleccionFechas();
    // abrimos dialogo
    // this.formularioService.inputs = this.inputsFormularioSeleccionRango;
    this.dialogo.open(this.dialogoSeleccionFechas, {disableClose: true});
  }
  

  onCambioFormularioRangoTipo(event, idFormulario, indice) {
    console.log('Evento ', idFormulario, indice);
  }

  onClickAnhadirRango() {
    console.log('Alta rango ', 'formularioSemillaSeleccionFechas');
    // this.grabando =  true;
    // reinicio proceso, en caso de alguna fecha seleccionada previa desaparce
    // this.vaciarSeleccion();
    // this.onIntercambioModoSeleccionFechas();
    if (this.formularioSemillaSeleccionFechas) {
      this.formularioSemillaSeleccionFechas.formValueReset = true;
      let objeto = this.formularioSemillaSeleccionFechas.validaCampos( false );
      if (objeto &&  objeto.formulario && objeto.formulario.valid) {
        this.rangosTipoSeleccionados.push(objeto.valores);
        this.formularioSemillaSeleccionFechas.reset({});

        let fechas = eachDayOfInterval({start: objeto.valores.rango.start._d, end: objeto.valores.rango.end._d});
        // aplicacion clase seleccion a la fechas visualizadas
        fechas.forEach( fecha => {
          let diaCalendarioVistaMes = this.fechaRenderizada(fecha);
          if (diaCalendarioVistaMes) {
            // vista actual en caso de no renderizar calendario
            // this.seleccionDiasMes(diaCalendarioVistaMes);
          } else {
            // this.anhadirFecha(fecha);
          }
        });
        // console.log('[CALENDARIO EMIT] clickElementosSeleccionados', fechas);
        // this.clickElementosSeleccionados.emit(fechas);
        // this.grabando=false;
        // this.dialogo.closeAll();

      } else {
        console.warn('formulario semilla error', objeto);
        // this.grabando = false;
        // return;
      }
    } else {
      console.warn('No detectado formulario semilla');
    }
  }

  onClickAltaEvento(event) {
    if (this.formularioGestionEventoDefecto) {
      this.abrirDialogoFormulario(new Date());
    } else {
      this.clickAltaEvento.emit();
    }
  }

  onClickCambioTipoDia(event) {
    if (this.formularioGestionTipoDiaDefecto) {
      this.dialogo.open(this.dialogoFormularioTipoDia, { disableClose: true });
    }
    this.clickCambioTipoDia.emit(event);
    // this.diaVista = event;
  }


  onSubmitAccionDialogoFormularioTipoDia(accion){
    if (accion.id === 'btn-confirmar') {
      console.log('establecer tarifa x en dia');
    }
    
  }

  onCerrarDialogoFormularioTipoDia(events){
  }

  onClickAccionCabecera (accionCabecera) {
    // console.log('[CALENDARIO EMIT] clickAccionCabeceraClick', accionCabecera);
    this.clickAccionCabeceraClick.emit(accionCabecera)
  }
  // ****************************************************** //
  // **************** ACCIONES SOBRE TIME****************** //
  // ********** cciones desde cuerpo calendario************ //
  // ****************************************************** //
  onClickEvento(evento) {
    console.log('onClick onClickEvento', evento);
    this.eventoSeleccionado = evento;
    this.cerrarDiaActivoAbierto();
    if (this.cerrarDialogoClickEvento) {
      this.dialogo.closeAll();
    }
    if (this.formularioGestionEventoDefecto) {
      this.abrirDialogoFormulario();
    }
    // console.log('[CALENDARIO EMIT] clickEvento', evento, evento.start);
    this.clickEvento.emit({ ...evento, fecha: evento.start });
  }

  esFechaBloqueda(date) {
    let dia = this.obtenerConfiguracionDia(date);
    return (dia && dia['bloqueo']);

  }

  // solo en vista mes, puede recibir =>  badgeTotal, date, day, events[], inMonth, isFuture, isPast, isToday, isWeekend
  // onClickMomentoMes({date,events}: {date: Date;events: CalendarEvent<{ film: Object }>[];}): void  {
  onClickMomentoMes(dia, evento): void {
    // console.log(`onClickMomentoMes en dia vista Mes ${dia.date} con los eventos ${dia.events}`);
    if ( this.modoSeleccion ) {
      this.seleccionDiasMes(dia);
    } else {
      if (this.clickCeldaMesDesplegarInfo) {
        // desplegar informacion
        if (isSameMonth(dia.date, this.diaVista)) {
          if ((isSameDay(this.diaVista, dia.date) && this.diaActivoEstaAbierto === true) || dia.events.length === 0) {
            this.diaActivoEstaAbierto = false;
          } else {
            this.diaActivoEstaAbierto = true;
            this.diaVista = dia.date;
          }
        }
      } else {
        let mostrarDiaSeleccionado = this.mostrarDiaSeleccionado || (this.mostrarDiaSeleccionadoConEventos && evento?.day?.events.length > 0);
        // click dia vista mes
        if (mostrarDiaSeleccionado) {
          this.vaciarSeleccion();
          this.seleccionDiasMes(dia);
        }
        this.onClickMomento(dia.date, CalendarView.Month, evento);      
      }
    }
  }

  onClickCeldaMesDesplegarInfo({ date, events }: { date: Date; events: CalendarEvent<{ film: Object }>[]; }, tarifaDia?: string) {
    // desplegar informacion en linea
    // if (isSameMonth(date, this.diaVista)) {
    //   if ((isSameDay(this.diaVista, date) && this.diaActivoEstaAbierto === true) || events.length === 0) {
    //     this.diaActivoEstaAbierto = false;
    //   } else {
    //     this.diaActivoEstaAbierto = true;
    //     this.diaVista = date;
    //   }
    // }
    let codigoColor = (tarifaDia) ? ((tarifaDia?.split('bg-'))[1]) : undefined;
    // open dialogoVerMasEventos
    let refDialogoVerMasEventos = this.dialogo.open(this.dialogoVerMasEventos, { disableClose: false, panelClass: 'dialogoVerMasEventos' });
    this.diaVista = date;
    events.map(item => { item.meta['entidad'].codigoColor = codigoColor; item.cssClass = tarifaDia })
    this.eventosDiaActivo = events;
  }

  onCerrarDialogoVerMasEventos(events){
    this.eventosDiaActivo = [];
  }


  onClickCeldaSemanaDesplegarInfo(segmento, eventos) {
    console.log('segmentos ', segmento, 'eventos ', eventos);
    let codigoColor = ((segmento.cssClass?.split('bg-'))[1]);
    // open dialogoVerMasEventos
    let refDialogoVerMasEventos = this.dialogo.open(this.dialogoVerMasEventos, { disableClose: false, panelClass: 'dialogoVerMasEventos' });
    this.diaVista = segmento.date;
    eventos.map(item => { item.meta['entidad'].codigoColor = codigoColor; item.cssClass = segmento.cssClass })
    this.eventosDiaActivo = eventos;
  }

  onClickMomentoSemana(fecha) {
    this.onClickMomento(fecha, CalendarView.Week);
  }
    
  onClickMomentoDia(fecha) {
    this.onClickMomento(fecha, CalendarView.Day);
  }

  // general vistas
  onClickMomento(fecha: Date, vista: VistasCalendario, evento?) {
    this.cerrarDiaActivoAbierto();
    // console.log(`Emit click en dia ${fecha} vista ${vista}`);
    if (this.formularioGestionEventoDefecto) {
      this.abrirDialogoFormulario(fecha);
    }

    // console.log('[CALENDARIO EMIT] clickMomento', {fecha, vista});
    let eventosDia = evento?.day?.events;
    this.clickMomento.emit({ fecha, vista, eventosDia });
  }

  cerrarDiaActivoAbierto() {
    this.diaActivoEstaAbierto = false;
    
  }

  // **************************************************** //
  // **************** NOTIFICACIONES********************* //
  // **************************************************** //
  showResult(message: string, status: string, dialogAction?: DialogActionEnum) {
    
    this.notificacion.eliminarNotificaciones();
    this.accionDialogoFormulario?.notificacionDialogo.eliminarNotificaciones();
    this.grabando=false;
    if (status === 'ok') {
      this.eventoSeleccionado=false;
      this.dialogo.closeAll();
      this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
    } else {
      switch (dialogAction) {
        case DialogActionEnum.Create:
        case DialogActionEnum.Update:
         this.accionDialogoFormulario?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;
        case DialogActionEnum.Delete:
          this.accionDialogoConfirmarEliminar?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;

        default:
          this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error ', message, true, IconEnum.Error);
      }
      
    }
  }

  // **************************************************** //
  // ******* ADAPATACION LAYOUT SEMANA DIA*************** //
  // **************************************************** //
  // eventos no todo el dia
  obtenerEventos(segmentos) {
    let eventosSegmentoHora = this.eventos.filter(item => {
      if (item?.start && !isNaN(item.start)) {
        let enRango = this.auxiliarService.fechaEntre(item.start, segmentos.date, addMinutes(segmentos.date, 60));
        return !item.allDay && enRango;
      } else {
        return false;
      }
    });
    // console.log('Eventos para ', segmentos, this.eventos, eventosSegmentoHora);
    return eventosSegmentoHora;
  }

  obtenerDia(columna) {
    let dia = columna.date;
    let configuracionDia = this.configuracionDias?.find(item => {
      let fechaColumna = new Date(dia);
      let fechaSinHoras = new Date(fechaColumna.getFullYear(), fechaColumna.getMonth(), fechaColumna.getDate());
      return item.dia == fechaSinHoras.getTime()
    });
    return configuracionDia;
  }

  obtenerConfiguracionDia(dia) {
    let configuracionDia = this.configuracionDias?.find(item => {
      let fechaColumna = new Date(dia);
      let fechaSinHoras = new Date(fechaColumna.getFullYear(), fechaColumna.getMonth(), fechaColumna.getDate());
      return item.dia == fechaSinHoras.getTime()
    });
    return configuracionDia;
  }


  // **************************************************** //
  // **************** RESPONSIVO************************* //
  // **************************************************** //

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.chequearEstadoPorTamanho();
  }
  
  get numeroEtiquetas() {
    return 3;
  }

  get numeroEtiquetasWeek() {
    return 3;
  }


  chequearEstadoPorTamanho() {
    // this.visibilidadChipsEventos = (window.innerWidth > environment.MEDIA_MOBILE);
    this.textoVerMasChipsEventosResponsivo = (window.innerWidth <= environment.MEDIA_MOBILE);
  }

  // **************************************************** //
  // **************** CLASES PERSONALIZADO DIA ********** //
  // **************************************************** //

  // renderizado, custom celdas  
  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    if (true) {
      // console.log('renderizado', renderEvent.body);
      this.diasMesRenderizados = renderEvent.body;
      renderEvent.body.forEach((day) => {
        if (!this.esFechaValida(day.date)) {
          day.cssClass = this.claseDesactivar;
        } else {
          if (this.existeFecha(day.date)) {
            day.cssClass = this.claseSeleccion;
          } else {
            this.aplicarClasesDias(day)
          }
        }
      });
    }
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    // this.addSelectedDayViewClass();
    // date, events, hours[]
    //header 7 elementos

    console.log(renderEvent);
    renderEvent.header.map(diaCabecera => {
      let configuracionDia = this.configuracionDias?.find(item => item.dia == diaCabecera.date.getTime());
      if (configuracionDia) {
        diaCabecera['color'] = configuracionDia['color'];
        diaCabecera['cssClass'] = configuracionDia['cssClass'];
        diaCabecera['locked'] = configuracionDia['bloqueo'];
      }

    })

    if (renderEvent.allDayEventRows.length <= 0) {
      let row: WeekViewAllDayEventRow = { 'row': [] }
      renderEvent.allDayEventRows.push(row);
    }  

    renderEvent.hourColumns.forEach((hourColumn) => {
      if (this.configuracionDias?.length) {

        this. aplicarClasesSegmentoHora(hourColumn);
      }
    });
  }

  beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent) {
    renderEvent.header.map(diaCabecera => {
      let configuracionDia = this.configuracionDias?.find(item => item.dia == diaCabecera.date.getTime());
      if (configuracionDia) {
        diaCabecera['color'] = configuracionDia['color'];
        diaCabecera['cssClass'] = configuracionDia['cssClass'];
        diaCabecera['locked'] = configuracionDia['bloqueo'];
      }
      this.configuracionDiaVista = diaCabecera;
    })

    // solucion para mostrar configuracion dia en seccion todos los eventos la cual se ocultaba si no existe ningun evento.
    if (renderEvent.allDayEventRows.length <= 0) {
      let row: WeekViewAllDayEventRow = { 'row': [] }
      renderEvent.allDayEventRows.push(row);
    }  

    renderEvent.hourColumns.forEach((hourColumn) => {
      if (this.configuracionDias?.length) {
        if (this.configuracionDias?.length) {
          this. aplicarClasesSegmentoHora(hourColumn);
        }
      }
    });
  }

  private aplicarClasesDias(dia): boolean{
    let itemPersonalizado = this.auxiliarService.busquedaPropiedad(this.configuracionDias, 'dia', dia.date.getTime());
    if (itemPersonalizado) {
      dia.cssClass = itemPersonalizado.clase;
      if (itemPersonalizado?.color) {
        dia['color'] = itemPersonalizado.color;
      }
      dia['locked'] = itemPersonalizado?.bloqueo;
      // console.log('Dia', dia, itemPersonalizado);

      return true;
    }else {
      return false;
    }
  }

  private aplicarClasesSegmentoHora(hourColumn): boolean {
    let itemPersonalizado = this.auxiliarService.busquedaPropiedad(this.configuracionDias, 'dia', hourColumn.date.getTime());
    if (itemPersonalizado) {
      hourColumn['color'] = itemPersonalizado['color'];
      hourColumn['cssClass'] = itemPersonalizado['cssClass'];
      hourColumn['locked'] = itemPersonalizado['bloqueo'];
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          segment.cssClass = itemPersonalizado.clase;
        });
      });
      return true;
    } else {
      return false;
    }
  }
  // **************************************** //
  // ******************* SELECCION ********** //
  // **************************************** //

  obtenerDiasSeleccionados() {
    return this.diasSeleccionados;
  }

  vaciarSeleccion() {
    this.notificacion.eliminarNotificaciones();
    this.modoSeleccion = false;
    this.diasSeleccionados = [];
    this.refrescarVista();
  }

  private seleccionDiasMes (dia) {
    this.seleccionDiaVistaMes = dia;
  
    if (this.existeFecha(dia.date)) {
      this.reestablercerClaseCss(dia.date);
    } else {
      // console.log('[CALENDARIO EMIT] clickElementosSeleccionados', [dia.date]);
      this.clickElementosSeleccionados.emit([dia.date]);
      this.anhadirFecha(dia.date);
      dia.cssClass = this.claseSeleccion;
      this.seleccionDiaVistaMes = dia;
    }
  }



  // de aquellos elemento renderizados que coincidan con la fecha
  private reestablercerClaseCss(fecha) {
    let diaCalendarioVistaMes = this.fechaRenderizada(fecha);
    if (diaCalendarioVistaMes) {
      if (!this.aplicarClasesDias(diaCalendarioVistaMes)) {
        delete diaCalendarioVistaMes.cssClass;
      }
    }

    this.eliminarFecha(fecha);    
  }

  submitAccionSeleccionFechas(accion) {
    if (accion.id == 'btn-confirmar') {
      // this.onClickAnhadirRango();
      // recuperamos todos los formularios seleccion rango
      let rangosTiposSeleccionadas: IRangoTipoSeleccion[] = [];
      this.formulariosSeleccionFechas.forEach(item => {
        if (item.identificador.indexOf('formularioSeleccionFechas_')>=0) {
          item.formValueReset = true;
          let objeto = item.validaCampos( false );
          if ((objeto &&  objeto.formulario && objeto.formulario.valid) ) {
            console.log('Valores ', objeto.valores);
            let rango = {start: format(objeto.valores.rango.start._d, 'dd/MM/yyyy'),
                        end:  format(objeto.valores.rango.end._d, 'dd/MM/yyyy')};
                        console.log('RANGO ', rango);
            objeto.valores.rango = rango;
            rangosTiposSeleccionadas.push({...objeto.valores});
            // let fechas = eachDayOfInterval({start: objeto.valores.rango.start._d, end: objeto.valores.rango.end._d});
            // // aplicacion clase seleccion a la fechas visualizadas
            // fechas.forEach( fecha => {
            //   let diaCalendarioVistaMes = this.fechaRenderizada(fecha);
            //   console.log('Fecha ', diaCalendarioVistaMes, fecha);
            //   this.anhadirFecha(fecha);
            //   // if (diaCalendarioVistaMes) {
            //   //   // vista actual en caso de no renderizar calendario
            //   //   // this.seleccionDiasMes(diaCalendarioVistaMes);
            //   // } else {
            //   //   // this.anhadirFecha(fecha);
            //   // }
            // });

          }
        }
      });
      this.clickAplicarRangoTipo.emit(rangosTiposSeleccionadas);
      this.dialogo.closeAll();
    }
  }

  // submitAccionSeleccionFechas(data) {
  //   // aplicar clases dias
  //   this.grabando =  true;
  //   // reinicio proceso, en caso de alguna fecha seleccionada previa desaparce
  //   this.vaciarSeleccion();
  //   // this.onIntercambioModoSeleccionFechas();
  //   if (this.formularioSeleccionFechas) {
  //     let objeto = this.formularioSeleccionFechas.validaCampos( false );
  //     if (objeto &&  objeto.formulario && objeto.formulario.valid) {
  //       let fechas = eachDayOfInterval({start: objeto.valores.rango.start._d, end: objeto.valores.rango.end._d});
  //       // aplicacion clase seleccion a la fechas visualizadas
  //       fechas.forEach( fecha => {
  //         let diaCalendarioVistaMes = this.fechaRenderizada(fecha);
  //         if (diaCalendarioVistaMes) {
  //           // vista actual en caso de no renderizar calendario
  //           this.seleccionDiasMes(diaCalendarioVistaMes);
  //         } else {
  //           this.anhadirFecha(fecha);
  //         }
  //       });
        // console.log('[CALENDARIO EMIT] clickElementosSeleccionados', fechas);
  //       this.clickElementosSeleccionados.emit(fechas);
  //       this.grabando=false;
  //       this.dialogo.closeAll();

  //     } else {
  //       this.grabando = false;
  //       return;
  //     }
  //   }
  // }


  private fechaRenderizada (fecha) {
    if (this.diasMesRenderizados?.length) {
      return this.auxiliarService.busquedaPropiedadFechas(this.diasMesRenderizados, 'date', fecha.getTime());
    }
  }

  // hay fecha
  private existeFecha(fecha ) {
    return this.diasSeleccionados.map( item => item.getTime()).includes(fecha.getTime());
  }

  private posicionFecha(fecha) {
    return this.diasSeleccionados.map( item => item.getTime()).indexOf(fecha.getTime());
  }

  private anhadirFecha(fecha) {
    if (!this.existeFecha(fecha)) {
      this.diasSeleccionados.push(fecha);
    }
  }

  private eliminarFecha(fecha) {
    let posicionFecha = this.posicionFecha(fecha);
    if (posicionFecha>=0) {
      this.diasSeleccionados.splice(posicionFecha, 1);
    }
  }


  // **************************************** //
  // ******************* FILTRADO ********** //
  // **************************************** //

  onEventoCambioFormularioFiltros(formulario: Object){
      this.determinarCambioValoresFiltradoIniciales(formulario);
      this.guardarFiltrosStorage(formulario);
      this.valoresFiltrado = Object.assign({}, formulario);
      if (this.filtradoSinBotonSubmit) {
        if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
        this.filtrar();
      }
    }
  
  determinarCambioValoresFiltradoIniciales(entidadFormulario) {
    JSON.stringify(entidadFormulario) !== JSON.stringify(this.valoresInicialesFiltrado) ? this.cambioValoresFiltrado = true : this.cambioValoresFiltrado = false;
  }
  

  onLimpiarBuscador() {
    this.valoresFiltrado =  [];
    this.filtrosService.clearFiltros(this.keyFiltrado);
    this.filtrosBusqueda.clearBuscador();
    this.filtrosTabla.forEach(filtroTabla => {
      filtroTabla.clearFilter();
    });
    this.cambioValoresFiltrado = false;

  }
  onSubmitFiltrado() {
    if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }
  onEventoCambioBuscador(valor){
    console.log('cambio buscador ', valor);
    valor ? this.cambioValorBuscador = true : this.cambioValorBuscador = false;
    this.valoresFiltrado['busqueda'] = valor;
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }

  private filtrar() {
    // console.log('[CALENDARIO EMIT] clickFiltrar', this.valoresFiltrado);
    this.clickFiltrar.emit(this.valoresFiltrado);
  }

  private obtenerValoresFiltrado() {
    return this.filtrosService.getFiltros(this.keyFiltrado, this.valoresInicialesFiltrado);
  }

  private establecerFiltros() {
    this.filtrosTablaService.inputsForm =  this.inputsFiltrado;
    // TODO BORRAR EN CUANTO APLICADO COMMIT EN MASTER EVITA DEFINIR VALIDADORES PARA CONTRUIR FORMULARIO TABLA
    // this.filtrosTablaService.validadoresForm = this.validatorsFiltros;
  }

  //TODO: AÑADIR FLAG PARA GUARDAR INPUT BÚSQUEDA
  private guardarFiltrosStorage(valoresFiltrado: object) {
    let filtradoSinBusqueda = Object.assign({}, valoresFiltrado);
    delete filtradoSinBusqueda['busqueda'];
    this.filtrosService.setFiltros(this.keyFiltrado, filtradoSinBusqueda);
  }



  // ****************************************************************** //
  // ******************* SOLUCION DEFECTO DIALOGO FORMULARIO ********** //
  // ****************************************************************** //
  tituloDialogo;
  eventoSeleccionado;
  valoresFormulario;

  abrirDialogoFormulario(fecha?:Date) {
    if(this.formularioGestionEventoDefecto) {
      console.log('EVENTO SELECCIONADO ', this.eventoSeleccionado);
      if ( this.eventoSeleccionado) {
        this.valoresFormulario =  Object.assign([], { ...this.eventoSeleccionado, ...this.eventoSeleccionado.meta.entidad, horaInicio: this.eventoSeleccionado.start, horaFin: this.eventoSeleccionado.end})
        console.log('valoresFormulario', this.valoresFormulario);
      } else{
        this.valoresFormulario =  Object.assign([], { allDay: true, start: fecha, end: fecha, horaInicio: fecha, horaFin: addMinutes(fecha, this.configuracion.intervaloMinutosCampoHora) });
      }
      this.dialogo.open(this.dialogoFormulario, {disableClose: true});
    }
  }
  onCambioFormularioEvento(datosCambio: any, identificadorFormulario?: string) {
    console.log('Evento ', this.eventoSeleccionado);
    // if( datosCambio?.campos.length && datosCambio.campos.includes('allDay')) {
      if (datosCambio.event.allDay) {
        this.formularioFormulario?.formValidators.get('horaInicio').disable();
        this.formularioFormulario?.formValidators.get('horaFin').disable();
      }else {
        this.formularioFormulario?.formValidators.get('horaInicio').enable();
        this.formularioFormulario?.formValidators.get('horaFin').enable();
      }

      if( datosCambio?.campos.length && datosCambio.campos.includes('horaInicio') && !datosCambio.event.allDay) {
        if(!datosCambio.event.horaInicio) {
          this.formularioFormulario.formValidators.get('horaInicio').setValue(datosCambio.event.start);
        } else {
          let horaFin = addMinutes(new Date(datosCambio.event.horaInicio), this.configuracion.intervaloMinutosCampoHora);
          this.formularioFormulario.formValidators.get('horaFin').setValue(horaFin);
        }        
      }

      // pre validaciones
      if( datosCambio?.campos.length && datosCambio.campos.includes('start') && compareAsc(new Date(datosCambio.event.start), new Date(datosCambio.event.end) ) == 1) {
        this.formularioFormulario.formValidators.get('end').setValue(datosCambio.event.start);
      } 

      if( datosCambio?.campos.length && datosCambio.campos.includes('horaFin') && compareAsc(new Date(datosCambio.event.horaInicio), new Date(datosCambio.event.horaFin) ) == 1) {
        let horaFin = addMinutes(new Date(datosCambio.event.horaInicio), this.configuracion.intervaloMinutosCampoHora);
        this.formularioFormulario.formValidators.get('horaFin').setValue(horaFin);
      } 
  }
  onSubmitAccionDialogoFormulario(accion){
    console.log('Emit accion botonera acciones dialogo', accion);
    if (accion.id === 'btn-confirmar') {
      this.grabando =  true;
      let objeto = this.formularioFormulario.validaCampos( false );
      if (objeto &&  objeto.formulario && objeto.formulario.valid) {
        let valores = objeto.valores;
        // console.log('[CALENDARIO EMIT] clickGestionarEvento', {...valores, meta: this.eventoSeleccionado?.meta});
        this.clickGestionarEvento.emit({...valores, meta: this.eventoSeleccionado?.meta});
      } else {
        this.grabando = false;
        this.showResult(`Debe rellenar los todos los campos obligatorios`, 'ko', DialogActionEnum.Create);
        return;
      }      
    } else {
      if (accion.id == 'btn-eliminar') {
        this.dialogo.closeAll();
        this.dialogo.open(this.dialogConfirmarEliminar, {disableClose: false});
      }
    }    
  }

  onSubmitAccionDialogConfirmarEliminar(accion) {
    if (this.eventoSeleccionado && accion.id == 'btn-confirmar') {
      // console.log('[CALENDARIO EMIT] clickEliminarEvento', this.eventoSeleccionado);
      this.clickEliminarEvento.emit(this.eventoSeleccionado);
    } else {
      this.onCerrarDialogoFormulario();
    }
  }

  onCerrarDialogoFormulario() {
    this.eventoSeleccionado = null;
  }

  
  // ****************************************************************** //
  // ******************* DESHABILITAR FECHAS                 ********** //
  // ****************************************************************** //
  esFechaValida(date: Date): boolean {
    let validacion = true;
    if (this.minFecha && this.maxFecha) {
      validacion = date >= this.minFecha && date <= this.maxFecha;
    } else {
      validacion = (this.minFecha || this.maxFecha) ?
      (this.minFecha) ? date >= this.minFecha : date <= this.maxFecha
      : true;
    }
    if (validacion && this.deshabilitarDiasSinClaseEspecificada && !this.modoSeleccion) {
      // control de fechas que no tienen clase aplicada
      let item = this.auxiliarService.busquedaPropiedad(this.configuracionDias, 'dia', date.getTime());
      validacion = (item && item.clase.length);
    }
    return validacion;
  }

  // ****************************************************************** //
  // ******************* COMPROBACION CORRECTA PARAMETRIZACION********** //
  // ****************************************************************** //
  private comprobarParametrosConfiguracion() {
    const IDENTIFICADOR = '[[CALENDARIO]]';
     // comprobaciones congruencia parametros
    switch(this.vista) {
      case CalendarView.Day:
        this.configuracion.vistaDia = true;
        break;
      case CalendarView.Week:
        this.configuracion.vistaSemana = true;
        break;
      case VistasCalendarioExtendidoEnum.Agenda:
        this.configuracion.vistaAgenda = true;
        break;
    }
    // if (this.permitirConfigurarCalendario && !this.accionesMultiples.length) {
    //   console.warn(IDENTIFICADOR + ' Debe introducir [accionesMultiples] para aplicar a la seleccion de fechas mediante la funcionalidad activa [permitirConfigurarCalendario]');
    // }


  }


 // ****************************************************************** //
  // ******************* AUXILIARES********** //
  // ****************************************************************** //
  imprimir(clave, datos) {
    console.log(clave, datos);
  }

  filtrarEventosTodoElDia(eventos): any[] {
    return eventos.filter(item=>item.meta?.entidad?.allDay && item.meta.entidad.allDay);
  }

  filtrarEventosRangoHorario(eventos): any[]  {
    return eventos.filter(item=>!(item.meta?.entidad?.allDay && item.meta.entidad.allDay));
  }
}

type CalendarPeriod = 'day' | 'week' | 'month';

function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}
